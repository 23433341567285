export default [
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'LEA',
        field: 'lea',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Course ID',
        field: 'courseId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Course Name',
        field: 'courseName',
        filter: false,
    },
    {
        headerName: 'Subject Code',
        field: 'subjectCore',
        filter: false,
    },
    {
        headerName: 'Subject',
        field: 'subject',
        filter: false,
    },
    {
        headerName: 'Credits',
        field: 'credits',
        filter: false,
    },
    {
        headerName: 'State Course ID',
        field: 'stateCourseId',
        filter: false,
    },
    {
        headerName: 'Department',
        field: 'department',
        filter: false,
    },
    {
        headerName: 'Course Name Short',
        field: 'courseNameShort',
        filter: false,
    },
    {
        headerName: 'Low Grade',
        field: 'lowGrade',
        filter: false,
    },
    {
        headerName: 'highGrade',
        field: 'High Grade',
        filter: false,
    },
    {
        headerName: 'Course Weight',
        field: 'courseWeight',
        filter: false,
    },
    {
        headerName: 'Special Education Course Indicator',
        field: 'specialEducationCourseIndicator',
        filter: false,
    },
    {
        headerName: 'Intervention Course Indicator',
        field: 'interventionCourseIndicator',
        filter: false,
    },
    {
        headerName: 'Tech Prep Course Indicator',
        field: 'techPrepCourseIndicator',
        filter: false,
    },
    {
        headerName: 'Tech-prep Course Indicator',
        field: 'techPrepCourseIndicator',
        filter: false,
    },
    {
        headerName: 'CA AG Requirement Category',
        field: 'cAAGRequirementCategory',
        filter: false,
    },
    {
        headerName: 'NCLB Instructional Level',
        field: 'nCLBInstructionalLevel',
        filter: false,
    },
    {
        headerName: 'Course Content Value',
        field: 'courseContentValue',
        filter: false,
    },
    {
        headerName: 'Non-Academic Code',
        field: 'nonAcademicCode',
        filter: false,
    },
    {
        headerName: 'Credits Possible',
        field: 'creditsPossible',
        filter: false,
    },
    {
        headerName: 'Max Credits',
        field: 'maxCredits',
        filter: false,
    },
]
